.title {
    word-break: break-all;
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 10px;
}
.description {
    word-break: break-all;
    line-height: 1.2;
}

.item {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
}

.modal {
    width: 1000px;
    height: 500px;
}
.titleModal {
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 0px;
}
.holder {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid #c5c5c5;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}
.left {
    display: flex;
    align-items: center;
    min-width: 400px;
    flex: 3;
    margin-right: 8px;
}
.image {
    width: 80px;
    height: 80px;
    min-width: 80px;
    object-fit: fill;
    img {
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
    margin-right: 20px;
}

.verified {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    color: green;
}
